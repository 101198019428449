<template>
  <div id="guardianRecover">
    <TopNavigation arrowAction="/" helpAction="/help"/>

    <div style="height: 60px"></div>

    <div class="flex-1 w-full guardian-login-form">
      <div class="guardian-login-form__title">
        <p style="font-size: 1.8em; font-family: var(--sans); margin: 25px 0 10px 0; font-weight: 700;">
          Forgot password
        </p>
      </div>
      <form
        autocomplete="off"
        class="guardian-login-form__content"
        @submit.prevent="userRecover"
      >
        <label for="">Enter your Guardian's Email address!</label>
        <input
          v-model="email"
          class="guardian-login-form__input"
          placeholder="ex: youremail@domain.com"
          type="email"
          spellcheck="false"
        />

        <label for="" class="mt-8">Enter your date of birth!</label>
        <input
          v-model="birthdate"
          class="guardian-login-form__input"
          placeholder="DD/MM/YYYY"
          type="date"
          spellcheck="false"
        />

        <button type="submit" class="guardian-login-form__button mt-10">
          <span v-if="isBtnLoading"
            ><svg
              id="L9"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve"
              width="40"
              height="40"
            >
              <path
                fill="#fff"
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="1s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite"
                />
              </path></svg></span>
          <span v-else>Request PIN</span>
        </button>
      </form>
    </div>
    <LoginFooter />
  </div>
</template>

<script>
  import axios from "axios";
  import config from "../../guardian/config";
  import status from "../../guardian/utils/statusCode";
  import LoginFooter from "../components/LoginFooter";
  import { mapActions } from "vuex";
  import TopNavigation from "./TopNavigation";

  export default {
    components: {
      LoginFooter,
      TopNavigation,
    },
    data() {
      return {
        isBtnLoading: false,
        families: [],
        selectedFamily: null,
        selectFamily: false,
        email: "",
        birthdate: "",
      };
    },
    computed: {
      validateEmail() {
        return this.email;
      },
    },
    methods: {
      ...mapActions(["TOAST"]),
      userRecover() {
        this.isBtnLoading = true;
        axios
          .post(config.API_HOST + "/reset-password-child", {
            email: this.email,
            date_birth: this.birthdate,
          })
          .then((response) => {
            const { statusCode } = response.data;

            if (status.success(statusCode)) {
              this.email = "";
              this.birthdate = "";
              this.TOAST(response.data.message);
              this.isBtnLoading = false;
              setTimeout(() => {
                this.$router.replace("/");
              }, 3000);
            } else {
              this.TOAST(response.data.message);
              this.isBtnLoading = false;
            }
          })
          .catch((error) => {
            throw error;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .main-top-nav {
    background: white;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    z-index: 100000;
    padding: 15px;
  }

  #child-login {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 15px;

    &__logo {
      height: 75px;
    }

    &__avatar {
      height: 100px;
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }

  #guardianRecover {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 15px;

    &__logo {
      height: 75px;
    }
  }

  .guardian-login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    margin-bottom: auto;

    &__content {
      background: white;
      border: 1px solid #dedede;
      padding: 20px;
      width: 85%;
      border: none;

      label {
        font-size: 14px;
        color: #777777;
        margin-bottom: 10px;
        display: block;
      }
    }

    &__input {
      background-color: #ebebeb;
      width: 100%;
      height: 48px;
      min-height: 48px;
      padding: 15px;
      border: 1px solid #d9d9d9;
      border-radius: 100px;
      outline: none;
      font-size: 14px;
      color: #444 !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &__title {
      margin-bottom: 40px;
      text-align: center;
      color: #01A4C0;
      width: 100%;

      h1 {
        font-size: 20px;
      }

      p {
        margin-top: 5px;
        font-size: 13px;
      }
    }

    &__button {
      width: 200px;
      min-width: 200px;
      margin: 0 auto;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 1px solid var(--main);
      background: var(--main);
      color: white;
      outline: none;
      font-size: 1rem;
      cursor: pointer;
      transition: transform 100ms ease-in-out;
      text-decoration: none;

      &:active {
        transform: scale(0.99);
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:focus {
        background-color: var(--main);
      }
    }

    &__forgot {
      text-align: center;
      font-size: 0.8rem;
      color: var(--main);
      display: block;
      margin-top: 20px;
    }

    &__register {
      margin-top: 25px;
      text-align: center;
      width: 85%;
      color: #777;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-left: 5px;
      }
    }
  }

  ::placeholder {
    color: #bebebe;
  }

  .mt {
    margin-top: 10px;
  }
</style>
